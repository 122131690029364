import { useCallback, useEffect, useState } from 'react'
import { useSearch } from '@faststore/sdk'
import Button from 'src/components/ui/Button'
import IconButton from 'src/components/ui/IconButton'
import SlideOver from 'src/components/ui/SlideOver'
import { useModal } from 'src/sdk/ui/modal/Provider'
import Icon from 'src/components/ui/Icon'

import { useFilter } from './useFilter'
import Facets from './Facets'
import SelectedFacets from './SelectedFacets'
import type { Facet, FacetValue } from './types'

import './filter.scss'

interface Props {
  facetsv2?: Facet[]
  isOpen?: boolean
  onDismiss?: () => void
  testId?: string
}

function Filter({
  facetsv2,
  onDismiss,
  isOpen = false,
  testId = 'store-filter',
}: Props) {
  const {
    setFacets,
    toggleFacet,
    setFacet,
    state: { selectedFacets },
  } = useSearch()

  const { onModalClose } = useModal()
  const { facets, selected, expanded, dispatch } = useFilter(facetsv2)

  const filterFacets = useCallback(() => {
    if (!facetsv2) {
      return []
    }

    return selected
      .map(
        (filter) =>
          facetsv2
            ?.find((facet) => facet.key === filter.key)
            ?.values.find((value) => value.value === filter.value) as FacetValue
      )
      .filter((item) => item?.key !== 'category-1' && item)
  }, [facetsv2, selected])

  const [filteredFacets, setFilteredFacets] = useState<FacetValue[]>(
    filterFacets()
  )

  function handleModalClose() {
    dispatch({
      type: 'selectFacets',
      payload: selectedFacets,
    })

    onModalClose()
  }

  useEffect(() => {
    setFilteredFacets(filterFacets())
  }, [filterFacets])

  return (
    <>
      <div className="hidden-mobile">
        {selected && filteredFacets && (
          <SelectedFacets
            filteredFacets={filteredFacets}
            selectedFacets={selected}
            removeAllFacet={() =>
              dispatch({ type: 'selectFacets', payload: [] })
            }
            removeFacet={toggleFacet}
          />
        )}
        <Facets
          facetsv2={facets}
          testId={`desktop-${testId}`}
          indicesExpanded={expanded}
          onFacetChange={(facet, type) => {
            if (type === 'RANGE') {
              setFacet(facet, true)
            } else {
              const newFacet = selectedFacets.filter(
                (obj) => obj.key !== facet.key
              )

              toggleFacet(facet)
              setFacets(newFacet)
            }
          }}
          onAccordionChange={(index) =>
            dispatch({ type: 'toggleExpanded', payload: index })
          }
        />
      </div>

      <SlideOver
        isOpen={isOpen}
        onDismiss={onDismiss}
        size="full"
        direction="leftSide"
        className="filter-modal__content"
      >
        <div className="filter-modal__body">
          <header className="filter-modal__header">
            <h2 className="title-display flex justify-center items-center">
              <Button onClick={() => handleModalClose()} className="p-0">
                <Icon
                  name="CaretLeftFilter"
                  width={24}
                  height={24}
                  className="mr-2"
                />
              </Button>
              <span className="mobile-body-semibold-text3 tablet-body-semibold-text3 text-restructure-primary">
                Filtros
              </span>
            </h2>
            <IconButton
              data-testid="filter-modal-button-close"
              classes="filter-modal__button"
              aria-label="Close Filters"
              icon={<Icon name="X" width={24} height={24} />}
              onClick={() => handleModalClose()}
            />
          </header>
          <Facets
            facetsv2={facets}
            testId={`mobile-${testId}`}
            indicesExpanded={expanded}
            onFacetChange={(facet, type) =>
              type === 'RANGE' ? setFacet(facet, true) : toggleFacet(facet)
            }
            onAccordionChange={(index) =>
              dispatch({ type: 'toggleExpanded', payload: index })
            }
          />
        </div>
        <footer className="filter-modal__footer">
          <Button
            variant="primary"
            data-testid="filter-modal-button-apply"
            className="w-full bg-restructure-background-primary-inverted text-restructure-primary-inverted tablet-body-semibold-text2 py-3 rounded-round"
            onClick={() => {
              setFacets(selected)
              onModalClose()
            }}
          >
            Aplicar
          </Button>
        </footer>
      </SlideOver>
    </>
  )
}

export default Filter
