export const IconPlusFilter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.33331C8.27614 3.33331 8.5 3.55717 8.5 3.83331V13.1666C8.5 13.4428 8.27614 13.6666 8 13.6666C7.72386 13.6666 7.5 13.4428 7.5 13.1666V3.83331C7.5 3.55717 7.72386 3.33331 8 3.33331Z"
        fill="#3E4751"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8335 8.5C2.8335 8.22386 3.05735 8 3.3335 8H12.6668C12.943 8 13.1668 8.22386 13.1668 8.5C13.1668 8.77614 12.943 9 12.6668 9H3.3335C3.05735 9 2.8335 8.77614 2.8335 8.5Z"
        fill="black"
      />
    </svg>
  )
}

export const IconMinusFilter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8335 8.5C2.8335 8.22386 3.05735 8 3.3335 8H12.6668C12.943 8 13.1668 8.22386 13.1668 8.5C13.1668 8.77614 12.943 9 12.6668 9H3.3335C3.05735 9 2.8335 8.77614 2.8335 8.5Z"
        fill="#3E4751"
      />
    </svg>
  )
}
