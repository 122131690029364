import { TextViewDraftJS } from '../../sections/campaign/common/TextViewDraftJS'
import './contentSEO.scss'

type Props = {
  dataCMS: any
}

export function ContentSEOBrand({ dataCMS }: Props) {
  const dataBrand = dataCMS.sections[0].data.items

  return (
    <div className="section-SEO mt-16">
      <TextViewDraftJS text={dataBrand.sectionA.brandTextSEO} />
    </div>
  )
}
