import type { ReactNode } from 'react'
import { useMemo, useState } from 'react'
import type { IStoreSelectedFacet } from '@faststore/api'

import { Accordion } from './Accordion'
import { Checkbox } from './Checkbox'
import type { Facet, VariantTypeCheckbox } from './types'

type Props = {
  facet: Facet
  defaultNumberOpts?: number
  checkboxType: VariantTypeCheckbox
  onFacetChange: (item: IStoreSelectedFacet, type?: 'RANGE' | 'BOOLEAN') => void
  icon: ReactNode
  onAccordionChange: (index: number) => void
}

export function FacetSection({
  facet,
  defaultNumberOpts = 8,
  checkboxType,
  onFacetChange,
  icon,
  onAccordionChange,
}: Props) {
  const [isCollapse, setIsCollapse] = useState(true)

  const isNecessaryShowMore = facet.values.length > defaultNumberOpts

  const options = useMemo(() => {
    const { values: facetValues, name } = facet

    // This sort the facets A-Z
    const sortedValues = [...facetValues].sort((a, b) => {
      if (a.label < b.label) {
        return -1
      }

      if (b.label < a.label) {
        return 1
      }

      return 0
    })

    const values = name === 'Cor predominante' ? sortedValues : facetValues

    if (!isCollapse) {
      return values
    }

    return values.slice(0, defaultNumberOpts)
  }, [facet, defaultNumberOpts, isCollapse])

  return (
    <Accordion
      title={facet.name}
      index={facet.key}
      icon={icon}
      onAccordionChange={() => onAccordionChange(Number(facet.key))}
      onCloseTab={() => setIsCollapse(true)}
    >
      <div className="mt-4">
        <div className="flex flex-wrap items-center gap-4">
          {options.map((value) => (
            <Checkbox
              type={checkboxType}
              key={value.value}
              facet={value}
              onFacetChange={onFacetChange}
            />
          ))}
        </div>

        {isNecessaryShowMore &&
          (isCollapse ? (
            <button
              className="mobile-body-semibold-text3 tablet-body-semibold-text3 desktop-body-semibold-text3 text-restructure-action mt-7"
              onClick={() => setIsCollapse(false)}
            >
              Ver todos
            </button>
          ) : (
            <button
              className="hidden mobile-body-semibold-text3 tablet-body-semibold-text3 desktop-body-semibold-text3 text-restructure-action mt-7 sm:block"
              onClick={() => setIsCollapse(true)}
            >
              Mostrar menos
            </button>
          ))}
      </div>
    </Accordion>
  )
}
