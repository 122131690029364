import { lazy } from 'react'
import { useSearch } from '@faststore/sdk'
import type { Product } from 'src/components/restructure/product/types/product'

import { Pagination } from './Pagination'

const GalleryPage = lazy(() => import('./ProductGalleryPageV2'))

type ProductsPerPage = {
  page: number
  products: Product[]
}

interface ProductResultListProps {
  title: string
  totalProducts: number
  productsByPage: ProductsPerPage[]
  registerPerPage: number
}

function ProductResultList({
  title,
  productsByPage,
  totalProducts,
  registerPerPage,
}: ProductResultListProps) {
  const { setPage, state } = useSearch()
  const products = productsByPage.find((item) => item.page === state.page)

  const productGroupOne = products?.products.slice(0, 12)
  const productGroupTwo = products?.products.slice(12, products.products.length)

  if (!products) {
    return null
  }

  return (
    <div className="product-listing__results">
      {productGroupOne && productGroupOne?.length > 0 && (
        <ul className="product-grid grid gap-6 sm:gap-[10px] md:gap-4 md:gap-y-[56px] mobile:grid-cols-1 tablet:grid-cols-1 small:grid-cols-3">
          <GalleryPage
            key={`gallery-page-${products.page}`}
            products={productGroupOne}
            page={products.page}
            title={title}
          />
        </ul>
      )}
      {/* <div className="relative flex flex-column">
        <ShelfLinx
          page={isSearchPage ? 'search' : 'category'}
          position="middle"
          classNameContainer="border-y border-mediumGray !py-4 !my-6"
          breakpoints={{
            240: {
              slidesPerView: 1,
            },
            320: {
              slidesPerView: 2,
            },
            800: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        />
      </div> */}
      {productGroupTwo && productGroupTwo.length > 0 && (
        <ul className="product-grid grid gap-6 sm:gap-[10px] md:gap-4 md:gap-y-[56px] mobile:grid-cols-1 tablet:grid-cols-1 small:grid-cols-3 !pt-0">
          <GalleryPage
            key={`gallery-page-${products.page}`}
            products={productGroupTwo}
            page={products.page}
            title={title}
          />
        </ul>
      )}
      <Pagination
        onPageChange={setPage}
        totalCountOfRegisters={totalProducts}
        currentPage={state.page === 0 ? 1 : state.page}
        registersPerPage={registerPerPage}
      />
    </div>
  )
}

export default ProductResultList
