interface PaginationItemProps {
  number: number
  isCurrent?: boolean
  onPageChange: (page: number) => void
}

export function PaginationItem({
  isCurrent = false,
  number,
  onPageChange,
}: PaginationItemProps) {
  if (isCurrent) {
    return (
      <button
        disabled
        aria-label={number.toString()}
        className="w-[40px] h-[40px] rounded-[4px] font-roboto text-white bg-primary cursor-pointer"
      >
        {number}
      </button>
    )
  }

  return (
    <button
      aria-label={number.toString()}
      className="w-[40px] h-[40px] rounded-[4px] font-roboto"
      onClick={() => onPageChange(number)}
    >
      {number}
    </button>
  )
}
