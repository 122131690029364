/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-bind */

import { useEffect, useState } from 'react'
import { useSearch } from '@faststore/sdk'
import { useLinxUser } from 'src/sdk/linx/hooks/useLinxUser'
import { getTopSearches } from 'src/utils/getTopSearchesLinx'
import { Link } from 'gatsby'
import { useMobile } from 'src/hooks/useMobile'
import { RecommendationProvider } from 'src/components/restructure/product/contexts/recommendations-context'
import GenericShelfv2 from 'src/components/restructure/product/shelves/GenericShelfV2'

interface TopSearch {
  term: string
}

type SearchNotFoundProps = {
  term?: string
}

function SearchNotFound({ term: searchTerm }: SearchNotFoundProps) {
  const [topSearches, setTopSearches] = useState<TopSearch[]>([])
  const { isMobile } = useMobile()
  const findTerm = useSearch()
  const getTerm = findTerm?.state.term

  const imgDevice = isMobile ? 'mob' : 'desk'

  const { getLinxUser } = useLinxUser()
  const linxUser = getLinxUser()

  useEffect(() => {
    if (linxUser.deviceId) {
      const handleGetTopSearches = async () => {
        const data = await getTopSearches(linxUser.deviceId)
        const { searches } = data

        setTopSearches(searches.slice(0, 8))
      }

      handleGetTopSearches()
    }
  }, [linxUser.deviceId])

  useEffect(() => {
    const obj = {
      event: 'search_error',
      page_full_url: window?.location?.href ?? '',
      page_title: 'Search Results | FastStore',
      page_path: window?.location?.pathname ?? '',
      full_referrer: document?.referrer ?? '',
      local_timestamp:
        new Date().toISOString().replace('T', ' ').replace('.', ' ') ?? '',
      local_timestamp_identifier:
        Intl.DateTimeFormat().resolvedOptions().timeZone ?? '',
      website_domain: window?.location?.host ?? '',
      referring_domain: document?.referrer ?? '',
      page_name: 'Search Results | FastStore',
      page_type: 'página de busca',
      page_language: 'pt-br',
      previous_page_type: 'NA',
      previous_page_name: 'NA',
      page_platform: 'vtex',
      page_number_viewed: 'NA',
      search_term: searchTerm ?? '',
      number_search_results: '0',
    }

    window.dataLayer = window?.dataLayer || []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(obj)
  }, [searchTerm])

  return (
    <div className="font-inter max-w-[1280px] w-full mx-auto mb-28 restructure-small-desktop:mt-6 restructure-small-desktop:mb-20">
      <div className="flex flex-col mb-24 restructure-small-desktop:flex-row-reverse restructure-small-desktop:gap-[128px] restructure-small-desktop:mb-[6.5rem]">
        <img
          className="w-full max-w-[708px]"
          src={`https://decathlonstore.vteximg.com.br/arquivos/empty-search-${imgDevice}.jpg`}
          alt="Empty search result"
        />
        <div className="mt-8 px-md restructure-small-desktop:mt-0">
          <h1 className="text-heading-sm text-[#101010] m-0 restructure-small-desktop:text-heading-lg">
            Não encontramos resultados para "{getTerm}".
          </h1>

          <p className="text-body-md text-[#383838] mt-5 mb-8 restructure-small-desktop:text-body-lg restructure-small-desktop:mt-6 restructure-small-desktop:mb-10">
            Você pode verificar a ortografia ou tentar termos de pesquisa menos
            específicos. Dá uma olhada nos termos mais buscados pelos
            esportistas:
          </p>

          <div className="flex flex-wrap gap-4">
            {topSearches.length
              ? topSearches.map(({ term }) => (
                  <div
                    className="flex flex-none justify-center items-center cursor-pointer py-xs px-sm bg-neutral02 text-body-sm text-center text-[#3E4751] border border-solid border-[#E1E4E7] rounded-full"
                    key={term}
                  >
                    <Link to={`/pesquisa?q=${term}&sort=score_desc&page=0`}>
                      {term}
                    </Link>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-24 px-md">
        <RecommendationProvider pageName="emptysearch">
          <GenericShelfv2 enableSession position="top" />
          <GenericShelfv2 enableSession position="middle" />
          <GenericShelfv2 enableSession position="bottom" />
          <GenericShelfv2 enableSession position="area bonus" />
        </RecommendationProvider>
      </div>
    </div>
  )
}

export default SearchNotFound
