import axios from 'axios'

export async function getTopSearches(deviceId: string) {
  try {
    const { data } = await axios.get(
      `https://decathlonstore.myvtex.com/_v/api/decathlon-search/top_searches`,
      {
        params: {
          engine: 'vtex',
          deviceId,
        },
      }
    )

    return data
  } catch (error) {
    console.error(error)

    return null
  }
}
