import type { ReactNode } from 'react'

import type { VariantTypeCheckbox } from './types'

type VariantFilterType = {
  [key: string]: {
    icon: ReactNode
    type: VariantTypeCheckbox
    defaultNumberOpts?: number
  }
}

export const variantsFilter: VariantFilterType = {
  Tamanho: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M3.28604 9.30475L4.70004 10.7188L5.64271 9.77609L4.22938 8.36209L5.64271 6.94809L7.52871 8.83342L8.47138 7.89075L6.58671 6.00475L8.00004 4.59075L9.41404 6.00475L10.3574 5.06209L8.94271 3.64809L10.8287 1.76209C11.089 1.50183 11.511 1.50183 11.7714 1.76209L15.0714 5.06209C15.3316 5.32242 15.3316 5.74442 15.0714 6.00475L5.17138 15.9048C4.91104 16.165 4.48904 16.165 4.22871 15.9048L0.928711 12.6048C0.668457 12.3444 0.668457 11.9224 0.928711 11.6621L3.28604 9.30475Z"
          fill="#687787"
        />
      </svg>
    ),
    type: 'box',
  },
  Promoção: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_47_5285)">
          <path
            d="M7.26673 1.40002L13.8661 2.34336L14.8087 8.94336L8.68073 15.0714C8.55571 15.1963 8.38617 15.2666 8.2094 15.2666C8.03262 15.2666 7.86308 15.1963 7.73806 15.0714L1.13806 8.47136C1.01308 8.34634 0.942871 8.1768 0.942871 8.00002C0.942871 7.82325 1.01308 7.65371 1.13806 7.52869L7.26673 1.40002ZM9.15206 7.05736C9.2759 7.18115 9.4229 7.27934 9.58469 7.34632C9.74647 7.4133 9.91986 7.44776 10.095 7.44773C10.2701 7.4477 10.4434 7.41318 10.6052 7.34614C10.767 7.2791 10.9139 7.18086 11.0377 7.05703C11.1615 6.93319 11.2597 6.78618 11.3267 6.6244C11.3937 6.46261 11.4281 6.28922 11.4281 6.11412C11.4281 5.93902 11.3935 5.76564 11.3265 5.60388C11.2595 5.44212 11.1612 5.29515 11.0374 5.17136C10.9136 5.04757 10.7666 4.94938 10.6048 4.8824C10.443 4.81542 10.2696 4.78096 10.0945 4.78099C9.74086 4.78105 9.40174 4.92159 9.15173 5.17169C8.90172 5.42179 8.7613 5.76096 8.76136 6.11459C8.76142 6.46823 8.90196 6.80735 9.15206 7.05736Z"
            fill="#D9DDE1"
          />
        </g>
        <defs>
          <clipPath id="clip0_47_5285">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    type: 'default',
  },
  Esporte: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <g clipPath="url(#clip0_3905_21128)">
          <path
            d="M7.99998 1.83325C11.682 1.83325 14.6666 4.81792 14.6666 8.49992C14.6666 12.1819 11.682 15.1666 7.99998 15.1666C4.31798 15.1666 1.33331 12.1819 1.33331 8.49992C1.33331 4.81792 4.31798 1.83325 7.99998 1.83325ZM9.11331 11.1666H6.88665L5.96665 12.4313L6.33598 13.5686C6.8731 13.7445 7.43479 13.8338 7.99998 13.8333C8.58065 13.8333 9.13998 13.7399 9.66398 13.5686L10.0326 12.4313L9.11265 11.1666H9.11331ZM3.52931 7.74792L2.66798 8.37259L2.66665 8.49992C2.66665 9.65325 3.03265 10.7206 3.65465 11.5933H4.92798L5.80998 10.3799L5.12465 8.26659L3.52931 7.74792ZM12.4706 7.74792L10.8753 8.26659L10.19 10.3799L11.0713 11.5933H12.3446C12.9892 10.6907 13.3349 9.60898 13.3333 8.49992L13.3313 8.37259L12.4706 7.74792ZM9.52665 3.38859L8.66665 4.01525V5.69325L10.4626 6.99792L11.9553 6.51325L12.3246 5.37792C11.6364 4.42517 10.6532 3.72595 9.52731 3.38859H9.52665ZM6.47198 3.38859C5.34601 3.72613 4.36278 4.42558 3.67465 5.37859L4.04398 6.51325L5.53665 6.99792L7.33331 5.69325V4.01525L6.47265 3.38859H6.47198Z"
            fill="#687787"
          />
        </g>
        <defs>
          <clipPath id="clip0_3905_21128">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    type: 'box',
  },
  Entrega: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M3.31048 0.826415C3.23312 0.538507 2.97101 0.333252 2.66675 0.333252H0.666748V1.66659H2.09408L3.32076 9.76614L3.33617 9.83976C3.41353 10.1277 3.67563 10.3329 3.9799 10.3329H12.6697L12.7469 10.3285C13.026 10.2961 13.2585 10.0903 13.3205 9.81054L14.6509 3.80875L14.6635 3.73104C14.7032 3.3447 14.3998 2.9978 14.0001 2.9978L3.64408 2.99792L3.32589 0.900043L3.31048 0.826415ZM5.33341 14.3333C4.59703 14.3333 4.00008 13.7363 4.00008 12.9999C4.00008 12.2635 4.59703 11.6666 5.33341 11.6666C6.06979 11.6666 6.66675 12.2635 6.66675 12.9999C6.66675 13.7363 6.06979 14.3333 5.33341 14.3333ZM11.3334 14.3333C10.597 14.3333 10.0001 13.7363 10.0001 12.9999C10.0001 12.2635 10.597 11.6666 11.3334 11.6666C12.0698 11.6666 12.6667 12.2635 12.6667 12.9999C12.6667 13.7363 12.0698 14.3333 11.3334 14.3333Z"
          fill="#D9DDE1"
        />
      </svg>
    ),
    type: 'default',
  },
  'Tipo de montagem': {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36328 0.47198C7.46381 0.284282 6.53524 0.284736 5.63595 0.473314C5.51434 1.27393 5.03491 1.97631 4.33328 2.38065C3.6318 2.7863 2.78374 2.85035 2.02928 2.55465C1.41736 3.24005 0.953062 4.04406 0.665283 4.91665C1.2987 5.42224 1.66728 6.18886 1.66662 6.99931C1.6672 7.80908 1.29916 8.57508 0.666617 9.08065C0.95262 9.95419 1.41635 10.7591 2.02862 11.4446C2.78316 11.1485 3.63152 11.2123 4.33328 11.618C5.036 12.0229 5.51581 12.726 5.63662 13.528C6.53618 13.7152 7.46476 13.7143 8.36395 13.5253C8.48556 12.7247 8.96499 12.023 9.66662 11.6186C10.3681 11.213 11.2162 11.1489 11.9706 11.4446C12.5825 10.7592 13.0468 9.95523 13.3346 9.08265C12.724 8.59398 12.3333 7.84265 12.3333 6.99998C12.3323 6.18975 12.7004 5.42318 13.3333 4.91731C13.0472 4.04401 12.5835 3.23935 11.9713 2.55398C11.2166 2.8499 10.3683 2.78585 9.66662 2.37998C8.9644 1.97552 8.48466 1.27321 8.36328 0.47198ZM8.99997 6.99997C8.99997 8.10454 8.10454 8.99997 6.99997 8.99997C5.8954 8.99997 4.99997 8.10454 4.99997 6.99997C4.99997 5.8954 5.8954 4.99997 6.99997 4.99997C8.10454 4.99997 8.99997 5.8954 8.99997 6.99997Z"
          fill="#D9DDE1"
        />
      </svg>
    ),
    type: 'default',
  },
  Categoria: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <g clipPath="url(#clip0_3905_20944)">
          <path
            d="M5.33333 3.16659H14V4.49992H5.33333V3.16659ZM2 2.83325H4V4.83325H2V2.83325ZM2 7.49992H4V9.49992H2V7.49992ZM2 12.1666H4V14.1666H2V12.1666ZM5.33333 7.83325H14V9.16659H5.33333V7.83325ZM5.33333 12.4999H14V13.8333H5.33333V12.4999Z"
            fill="#001018"
          />
        </g>
        <defs>
          <clipPath id="clip0_3905_20944">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    type: 'box',
  },
  Gênero: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <g clipPath="url(#clip0_3905_20823)">
          <path
            d="M1.33331 15.1665C1.33331 13.752 1.89522 12.3955 2.89541 11.3953C3.8956 10.3951 5.25216 9.83317 6.66665 9.83317C8.08113 9.83317 9.43769 10.3951 10.4379 11.3953C11.4381 12.3955 12 13.752 12 15.1665H1.33331ZM6.66665 9.1665C4.45665 9.1665 2.66665 7.3765 2.66665 5.1665C2.66665 2.9565 4.45665 1.1665 6.66665 1.1665C8.87665 1.1665 10.6666 2.9565 10.6666 5.1665C10.6666 7.3765 8.87665 9.1665 6.66665 9.1665ZM11.5753 10.6552C12.5951 10.9173 13.5062 11.4944 14.1789 12.3044C14.8516 13.1144 15.2516 14.116 15.322 15.1665H13.3333C13.3333 13.4265 12.6666 11.8425 11.5753 10.6552ZM10.2266 9.13784C10.7853 8.63815 11.2321 8.02606 11.5377 7.34167C11.8433 6.65727 12.0008 5.91603 12 5.1665C12.0014 4.25548 11.7684 3.35942 11.3233 2.5645C12.0784 2.71623 12.7577 3.12475 13.2456 3.72063C13.7336 4.31652 14.0001 5.06299 14 5.83317C14.0002 6.30815 13.8988 6.77768 13.7027 7.21029C13.5066 7.6429 13.2203 8.0286 12.863 8.34153C12.5057 8.65447 12.0856 8.88741 11.6309 9.02475C11.1762 9.16208 10.6975 9.20064 10.2266 9.13784Z"
            fill="#687787"
          />
        </g>
        <defs>
          <clipPath id="clip0_3905_20823">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    type: 'default',
  },
  Idade: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M7.99998 15.1666C4.31798 15.1666 1.33331 12.1819 1.33331 8.49992C1.33331 4.81792 4.31798 1.83325 7.99998 1.83325C11.682 1.83325 14.6666 4.81792 14.6666 8.49992C14.6666 12.1819 11.682 15.1666 7.99998 15.1666ZM5.33331 9.16658C5.33331 9.87383 5.61426 10.5521 6.11436 11.0522C6.61446 11.5523 7.29274 11.8333 7.99998 11.8333C8.70722 11.8333 9.3855 11.5523 9.8856 11.0522C10.3857 10.5521 10.6666 9.87383 10.6666 9.16658H5.33331ZM5.33331 7.83325C5.59853 7.83325 5.85288 7.72789 6.04042 7.54036C6.22796 7.35282 6.33331 7.09847 6.33331 6.83325C6.33331 6.56804 6.22796 6.31368 6.04042 6.12614C5.85288 5.93861 5.59853 5.83325 5.33331 5.83325C5.0681 5.83325 4.81374 5.93861 4.62621 6.12614C4.43867 6.31368 4.33331 6.56804 4.33331 6.83325C4.33331 7.09847 4.43867 7.35282 4.62621 7.54036C4.81374 7.72789 5.0681 7.83325 5.33331 7.83325ZM10.6666 7.83325C10.9319 7.83325 11.1862 7.72789 11.3738 7.54036C11.5613 7.35282 11.6666 7.09847 11.6666 6.83325C11.6666 6.56804 11.5613 6.31368 11.3738 6.12614C11.1862 5.93861 10.9319 5.83325 10.6666 5.83325C10.4014 5.83325 10.1471 5.93861 9.95954 6.12614C9.772 6.31368 9.66665 6.56804 9.66665 6.83325C9.66665 7.09847 9.772 7.35282 9.95954 7.54036C10.1471 7.72789 10.4014 7.83325 10.6666 7.83325Z"
          fill="#687787"
        />
      </svg>
    ),
    type: 'default',
  },
  'Cor predominante': {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.1854 5.7629C5.06569 7.27854 5.61674 8.77042 6.69295 9.84432C7.76641 10.92 9.25749 11.471 10.7725 11.3519C10.1813 13.4563 8.17222 14.8388 5.99547 14.6392C3.81873 14.4396 2.09466 12.7146 1.89612 10.5378C1.69758 8.36093 3.08069 6.35303 5.1854 5.7629ZM5.86984 5.62718C7.25027 5.46086 8.6305 5.93855 9.61279 6.9226C10.5962 7.90485 11.0735 9.28465 10.9073 10.6646C9.52707 10.8309 8.14706 10.3534 7.16479 9.36966C6.18105 8.38739 5.70355 7.00738 5.86984 5.62718ZM10.0871 6.45021C11.1628 7.52367 11.7138 9.01475 11.5946 10.5297C13.699 9.93855 15.0821 7.929 14.8824 5.75226C14.6828 3.57551 12.9578 1.85145 10.781 1.65291C8.60415 1.45437 6.59578 2.83794 6.00565 4.94266C7.52128 4.82295 9.01317 5.374 10.0871 6.45021Z"
          fill="#687787"
        />
      </svg>
    ),
    type: 'color',
    defaultNumberOpts: 10,
  },
  Avaliações: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M4.30331 13.1667L1.33331 15.5V3.16667C1.33331 2.98986 1.40355 2.82029 1.52858 2.69526C1.6536 2.57024 1.82317 2.5 1.99998 2.5H14C14.1768 2.5 14.3464 2.57024 14.4714 2.69526C14.5964 2.82029 14.6666 2.98986 14.6666 3.16667V12.5C14.6666 12.6768 14.5964 12.8464 14.4714 12.9714C14.3464 13.0964 14.1768 13.1667 14 13.1667H4.30331ZM4.66665 7.16667V8.5H5.99998V7.16667H4.66665ZM7.33331 7.16667V8.5H8.66665V7.16667H7.33331ZM9.99998 7.16667V8.5H11.3333V7.16667H9.99998Z"
          fill="#687787"
        />
      </svg>
    ),
    type: 'starts',
  },
  Marca: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.33333 2.5H2V7.83333H7.33333V2.5ZM7.33333 9.16667H2V14.5H7.33333V9.16667ZM8.66667 2.5H14V7.83333H8.66667V2.5ZM14 9.16667H8.66667V14.5H14V9.16667Z"
          fill="#687787"
        />
      </svg>
    ),
    type: 'box',
  },
  Condições: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <g clipPath="url(#clip0_3905_21164)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.667 1.83325L12.1268 3.29307L10.667 3.83325L12.1268 4.37343L12.667 5.83325L13.2072 4.37343L14.667 3.83325L13.2072 3.29307L12.667 1.83325ZM8.53068 6.63609L7.00017 2.49994L5.46965 6.63609L1.3335 8.16661L5.46965 9.69712L7.00017 13.8333L8.53068 9.69712L12.6668 8.16661L8.53068 6.63609ZM12.0002 9.83325L12.7204 11.7797L14.6668 12.4999L12.7204 13.2202L12.0002 15.1666L11.2799 13.2202L9.3335 12.4999L11.2799 11.7797L12.0002 9.83325Z"
            fill="#687787"
          />
        </g>
        <defs>
          <clipPath id="clip0_3905_21164">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    type: 'default',
  },
  'Marca própria': {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.33333 0H0V5.33333H5.33333V0ZM5.33333 6.66667H0V12H5.33333V6.66667ZM6.66667 0H12V5.33333H6.66667V0ZM12 6.66667H6.66667V12H12V6.66667Z"
          fill="#D9DDE1"
        />
      </svg>
    ),
    type: 'default',
  },
}

export const colorConfig: { [key: string]: string } = {
  cinza: '#848484',
  preto: '#000',
  prata: '#DADADA',
  roxo: '#AC45EB',
  laranja: '#FF600A',
  azul: '#007DBC',
  vermelho: '#EE0303',
  verde: '#2CAB0D',
  marrom: '#844F00',
  branco: '#FFFFFF',
  amarelo: '#F9D82C',
  rosa: '#E861CA',
  'n/a': '',
}
