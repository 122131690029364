import { Link } from 'gatsby'

function FilteredEmtpyGallery() {
  const currentUrl = window.location.href

  const [cleanFiltersUrl] = currentUrl.split('&')

  return (
    <div className="flex flex-col justify-center items-center font-inter text-[#101010] text-center max-w-[1280px] w-full mx-auto mt-14 mb-28 px-md restructure-small-desktop:mt-24 restructure-small-desktop:mb-40">
      <p className="text-body-md font-medium restructure-small-desktop:text-font-size-6">
        Desculpe, não há produtos disponíveis.
      </p>
      <p className="text-body-md font-medium restructure-small-desktop:text-font-size-6">
        Limpe os filtros aplicados e expanda sua pesquisa.
      </p>

      <Link to={cleanFiltersUrl} className="text-body-md underline mt-8">
        Limpar filtros
      </Link>
    </div>
  )
}

export default FilteredEmtpyGallery
