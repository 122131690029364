import { Link } from 'gatsby'

type Props = {
  dataCMS: any
}

export function RelatedResearchBrand({ dataCMS }: Props) {
  const dataBrand = dataCMS.sections[0].data.items.sectionB

  return (
    <>
      {dataBrand.terms.length && (
        <div style={{ marginTop: '106px' }}>
          <section className="section-title-seo">
            <span className="text-darkGray text-sm">Pesquisa relacionada</span>
            <p className="text-xl text-neutral10">{dataBrand.termsSEO}</p>
          </section>
          <div className="section-terms-seo mt-5">
            <ul className="flex flex-wrap gap-2.5">
              {/* eslint-disable-next-line */}
              {dataBrand.terms.map((item: any, index: number) => (
                <li
                  key={index}
                  className="border border-solid rounded p-1.5"
                  style={{ borderColor: '#D9DDE1' }}
                >
                  <Link
                    to={item.termURL}
                    className="text-sm text-darkGray"
                    rel="nofollow"
                  >
                    {item.termName}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}
