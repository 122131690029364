import { ArrowButton } from './ArrowButton'
import { PaginationItem } from './PaginationItem'

import './pagination.scss'

interface PaginationProps {
  totalCountOfRegisters: number
  registersPerPage?: number
  currentPage?: number
  onPageChange: (page: number) => void
}

const siblingsCount = 1

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter((page) => page > 0)
}

export function Pagination({
  totalCountOfRegisters,
  registersPerPage = 24,
  currentPage = 1,
  onPageChange,
}: PaginationProps) {
  const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage)

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : []

  const showProductsNumber =
    registersPerPage * currentPage > totalCountOfRegisters
      ? totalCountOfRegisters
      : registersPerPage * currentPage

  function prevPage() {
    onPageChange(currentPage - 1)
  }

  function nextPage() {
    onPageChange(currentPage + 1)
  }

  return (
    <div className="w-full flex items-center justify-center relative mt-6 pagination_container">
      <div className="flex items-center gap-2">
        {currentPage > 1 && (
          <ArrowButton direction="left" onClick={() => prevPage()} />
        )}
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem onPageChange={onPageChange} number={1} />
            {currentPage > 2 + siblingsCount && <p>...</p>}
          </>
        )}

        {previousPages.length > 0 &&
          previousPages.map((page) => (
            <PaginationItem
              onPageChange={onPageChange}
              key={page}
              number={page}
            />
          ))}

        <PaginationItem
          onPageChange={onPageChange}
          number={currentPage}
          isCurrent
        />

        {nextPages.length > 0 &&
          nextPages.map((page) => (
            <PaginationItem
              onPageChange={onPageChange}
              key={page}
              number={page}
            />
          ))}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && <p>...</p>}
            <PaginationItem onPageChange={onPageChange} number={lastPage} />
            <ArrowButton direction="right" onClick={() => nextPage()} />
          </>
        )}
      </div>

      <p className="absolute right-0 font-roboto text-sm text-darkGray pagination_resume">
        Mostrando {showProductsNumber} produtos de {totalCountOfRegisters}
      </p>
    </div>
  )
}
