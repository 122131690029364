import Skeleton from 'react-loading-skeleton'
import Section from 'src/components/sections/Section'
import type { PropsWithChildren } from 'react'
import { useMobile } from 'src/hooks/useMobile'

import 'src/components/product/ProductGridV2/product-grid.scss'
import 'react-loading-skeleton/dist/skeleton.css'

interface Props {
  loading?: boolean
}

interface SkeletonComponentProps {
  length: number
  styledDiv?: string
  styledSkeleton?: string
  height: number
}

function ProductFilterSkeleton({
  children,
  loading = true,
}: PropsWithChildren<Props>) {
  const { isMobile } = useMobile()

  const SkeletonComponentDesktop: React.FC<SkeletonComponentProps> = ({
    length,
    styledDiv,
    styledSkeleton,
    height,
  }) => {
    return (
      <div
        className={`border-b border-mediumGray py-[32px] pb-[12px] ${styledDiv} `}
      >
        {Array.from({ length }, (_, idx) => (
          <Skeleton
            key={idx}
            height={height}
            className={`mb-[20px] ${styledSkeleton}`}
            borderRadius={0}
          />
        ))}
      </div>
    )
  }

  return loading ? (
    isMobile ? (
      <Section className="w-full">
        <div className="flex justify-between mb-[25px]">
          <Skeleton height={41} width={204} borderRadius={0} />
          <Skeleton height={41} width={87} borderRadius={0} />
        </div>
        <div className="flex justify-between mb-[20px]">
          <Skeleton height={21} width={202} borderRadius={0} />
          <Skeleton height={21} width={110} borderRadius={0} />
        </div>
      </Section>
    ) : (
      <Section className="mt-[42px]">
        <Skeleton height={28} borderRadius={0} />
        <Skeleton height={82} className="mt-[24px]" borderRadius={0} />
        <Skeleton height={21} className="mt-[32px]" borderRadius={0} />
        <Skeleton height={80} className="mt-[24px]" borderRadius={0} />
        <Skeleton height={21} className="mt-[24px]" borderRadius={0} />

        <div className="border-b border-t  border-mediumGray py-[32px] pb-[32px] mt-[32px]">
          <Skeleton height={32} borderRadius={0} />

          <Skeleton height={56} className="mt-[23px]" borderRadius={0} />
        </div>

        <SkeletonComponentDesktop length={7} height={24} />
        <SkeletonComponentDesktop length={5} height={24} />
        <SkeletonComponentDesktop length={3} height={24} />
        <SkeletonComponentDesktop length={6} height={24} styledDiv="pb-[8px]" />
        <SkeletonComponentDesktop
          length={6}
          height={24}
          styledDiv="pb-[12px]"
        />
        <SkeletonComponentDesktop
          length={6}
          height={24}
          styledDiv="pb-[12px]"
        />
        <SkeletonComponentDesktop length={5} height={24} />
      </Section>
    )
  ) : (
    <>{children}</>
  )
}

export default ProductFilterSkeleton
