type Props = {
  direction: 'left' | 'right'
  onClick: () => void
}

export function ArrowButton({ direction, onClick }: Props) {
  return (
    <button
      onClick={onClick}
      aria-label={direction === 'right' ? 'Próxima página' : 'Página anterior'}
      className="w-[40px] h-[40px] flex items-center justify-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        className={direction === 'right' ? 'rotate-180' : ''}
      >
        <path
          d="M2.828 7.00023L7.778 11.9502L6.364 13.3642L0 7.00023L6.364 0.63623L7.778 2.05023L2.828 7.00023Z"
          fill="#8996A2"
        />
      </svg>
    </button>
  )
}
