import type { FacetValue, VariantTypeCheckbox } from '../types'
import { Box } from './Box'
import { Color } from './Color'
import { Default } from './Default'
import { Stars } from './Stars'

type Props = {
  type: VariantTypeCheckbox
  facet: FacetValue
  onFacetChange(value: { key: string; value: string }): void
  showQuantity?: boolean
}

export function Checkbox({
  type = 'default',
  facet,
  onFacetChange,
  showQuantity = true,
}: Props) {
  const variations = {
    box: Box,
    default: Default,
    starts: Stars,
    color: Color,
  }

  const Component = variations[type]

  return (
    <Component
      facetValue={facet}
      onFacetChange={onFacetChange}
      showQuantity={showQuantity}
    />
  )
}
