import { colorConfig } from '../../filtersConfig'
import type { FacetValue } from '../../types'

import './style.scss'

type Props = {
  facetValue: FacetValue
  onFacetChange(value: { key: string; value: string }): void
  showQuantity?: boolean
}

export function Color({ facetValue, onFacetChange }: Props) {
  return (
    <label
      htmlFor={facetValue.value}
      className="checkbox_color"
      title={facetValue.label}
      data-store-label
      data-testid="store-label"
    >
      <input
        type="checkbox"
        name={facetValue.key}
        id={facetValue.value}
        checked={facetValue.selected}
        onChange={() => {
          onFacetChange({ key: facetValue.key, value: facetValue.value })
        }}
        data-value={facetValue.value}
        data-quantity={facetValue.quantity}
        data-store-checkbox
      />

      <span style={{ background: colorConfig[facetValue.label] }} />
    </label>
  )
}
