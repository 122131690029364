import { TextViewDraftJS } from 'src/components/sections/campaign/common/TextViewDraftJS'
import './contentSEO.scss'
import { useState, useEffect } from 'react'

type Props = {
  dataCMS: any
}

export function ContentSEO({ dataCMS }: Props) {
  const [contentSeo, setContentSeo] = useState<any>()

  useEffect(() => {
    const data = dataCMS.sections.filter(
      (item: any) =>
        item.name === '5.6 Texto SEO [WEB]' &&
        (item.data.configuration === 'exibir website e aplicativo' ||
          item.data.configuration === 'exibir apenas website')
    )

    if (data.length) {
      setContentSeo(data[0].data)
    } else {
      setContentSeo(null)
    }
  }, [contentSeo, dataCMS.sections])

  return (
    <>
      {/* eslint-disable-next-line */}
      {contentSeo && contentSeo.contentSEO && (
        <div className="section-SEO mt-16">
          <TextViewDraftJS text={contentSeo.contentSEO} />
        </div>
      )}
    </>
  )
}
