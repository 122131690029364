import type { FacetValue } from '../../types'

import './style.scss'

type Props = {
  facetValue: FacetValue
  onFacetChange(value: { key: string; value: string }): void
  showQuantity?: boolean
}

export function Default({ facetValue, onFacetChange, showQuantity }: Props) {
  return (
    <label
      htmlFor={facetValue.value}
      className="checkbox_default font-roboto text-sm"
      data-store-label
      data-testid="store-label"
    >
      <input
        type="checkbox"
        name={facetValue.key}
        id={facetValue.value}
        checked={facetValue.selected}
        onChange={() => {
          onFacetChange({ key: facetValue.key, value: facetValue.value })
        }}
        data-value={facetValue.value}
        data-quantity={facetValue.quantity}
        data-store-checkbox
      />

      <span className="mobile-body-regular-text2 tablet-body-regular-text2 desktop-body-regular-text3 text-restructure-primary">
        {facetValue.label}{' '}
        {showQuantity && (
          <span className="mobile-body-regular-text2 tablet-body-regular-text2 desktop-body-regular-text2 text-restructure-tertiary">
            ({facetValue.quantity})
          </span>
        )}
      </span>
    </label>
  )
}
