import type { ReactSliderProps } from 'react-slider'
import ReactSlider from 'react-slider'

export const Slider = <T extends number | readonly number[]>(
  _props: ReactSliderProps<T>
) => {
  return (
    <ReactSlider
      {..._props}
      className=""
      renderThumb={(props) => (
        <div {...props} className="top-1/2 -translate-y-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <rect width="20" height="20" rx="10" fill="#3643BA" />
            <rect
              width="4"
              height="4"
              rx="2"
              transform="matrix(1 0 0 -1 8 12)"
              fill="white"
            />
          </svg>
        </div>
      )}
      renderTrack={(props, state) => {
        const points = Array.isArray(state.value) ? state.value.length : null
        const isMulti = points && points > 0
        const isLast = isMulti ? state.index === points : state.index !== 0
        const isFirst = state.index === 0

        return (
          <div
            {...props}
            className={`h-[4px] top-1/2 -translate-y-1/2   ${
              (isMulti ? isFirst || isLast : isFirst)
                ? 'bg-gray'
                : 'bg-restructure-background-action-1'
            }`}
          />
        )
      }}
      renderMark={(props) => {
        return <div {...props} />
      }}
    />
  )
}
