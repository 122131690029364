import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

import './style.scss'
import { IconMinusFilter, IconPlusFilter } from './utils/icons'

type Props = {
  title: string
  index: string | number
  icon: ReactNode
  onAccordionChange?: () => void
  children: ReactNode
  onCloseTab?: () => void
}

export function Accordion({
  title,
  index,
  icon,
  onAccordionChange,
  onCloseTab,
  children,
}: Props) {
  const [openTab, setOpenTab] = useState(true)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 768) {
      setOpenTab(false)
    }
  }, [])

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen)
  }

  return (
    <div
      className="border-b border-mediumGray py-6 restructure-small-desktop:pb-8"
      data-accordion-item
      data-testid="filter-accordion-item"
      data-fs-facets-accordion-item
    >
      <div
        className="flex justify-between items-center w-full header-tab-accordion-filter sm:hidden"
        onClick={() => {
          toggleAccordion()
          onAccordionChange?.()
          if (openTab) {
            setOpenTab(false)
            onCloseTab?.()
          } else {
            setOpenTab(true)
          }
        }}
        role="button"
        tabIndex={Number(index)}
        onKeyDown={() => {}}
        aria-hidden={!openTab}
        data-accordion-button
        aria-controls="desktop-store-filter-panel"
        data-testid="filter-accordion-button"
      >
        <h3 className="flex gap-2 items-center">
          {icon}
          <span className="mobile-body-semibold-text3 tablet-body-semibold-text3 text-restructure-secondary">
            {title}
          </span>
        </h3>
        {isAccordionOpen ? <IconMinusFilter /> : <IconPlusFilter />}
      </div>
      <h2 className="flex gap-2 items-center hidden sm:flex mb-6">
        {icon}
        <span className="desktop-body-semibold-text3 text-restructure-secondary">
          {title}
        </span>
      </h2>
      {openTab && <div role="region">{children}</div>}
    </div>
  )
}
