import { useState, useMemo, useCallback, useEffect } from 'react'
import axios from 'axios'
import qs from 'query-string'
import { useSearch } from '@faststore/sdk'
import type { Facet } from '@faststore/sdk/dist/search/useSearchState'
import SearchNotFound from 'src/components/common/SearchNotFound'
import { mark } from 'src/sdk/tests/mark'
import Skeleton from 'react-loading-skeleton'
import { useLinxSearchView } from 'src/sdk/linx/hooks/useLinxSearchView'
import FilterV2 from 'src/components/search/FilterV2'
import SortV2 from 'src/components/search/SortV2'
import type { Product } from 'src/components/restructure/product/types/product'
import type { ServerCollectionPageQueryQuery } from '@generated/graphql'
import Switch from 'src/components/search/FilterV2/Switch'
import Logo from 'src/components/ui/Logo/Logo'
import { useLocation } from '@reach/router'
import { useLinxUser } from 'src/sdk/linx/hooks/useLinxUser'
import { RelatedResearchBrand } from 'src/components/brands/RelatedResearch'
import { ContentSEOBrand } from 'src/components/brands/ContentSEO/'
import { RelatedResearch } from 'src/components/sports/RelatedResearch/RelatedResearch'
import { ContentSEO } from 'src/components/sports/ContentSEO'
import ProductGridSkeleton from 'src/components/skeletons/ProductGridSkeleton'
import ProductFilterSkeleton from 'src/components/skeletons/ProductFilterSkeleton'
import { B2C } from 'src/store-config/B2C-Config'
import { Statsig } from 'statsig-react'
import { useMobile } from 'src/hooks/useMobile'
import FilteredEmptyGallery from 'src/components/common/FilteredEmptyGallery'
import { sendSearchQueryEvent } from 'src/sdk/analytics/platform/intelligentSearch'

import { IconFilterMobile } from './utils/icons'
import Section from '../Section'
import ProductResultList from './ProductResultList'
import type { SearchResult } from './types'

import './product-gallery.scss'

type SearchEngine = 'vtex' | 'linx'

interface CmsData {
  sections: Array<{
    data: {
      // eslint-disable-next-line
      items: any
    }
    id: string
    name: string
  }>
  status: string
}

interface Props {
  title: string
  searchEngine: SearchEngine
  salesChannel?: number
  categoryTree?: ServerCollectionPageQueryQuery['collection']
  department?: {
    item: string
    name: string
    position: number
  }
  dataSports?: CmsData | null
  type: 'search' | 'collection' | 'seller' | 'category'
  sellerName?: string
  bannerMobile?: string
  bannerDesktop?: string
  collection?: string
  dataBrand?: CmsData | null
  typeValidation?:
    | 'byTermOrCollection'
    | 'byDepartment'
    | 'bySellerName'
    | 'byFilter'
}

export type ParamsApi = {
  sc: number
  engine?: SearchEngine
  term?: string | null
  page: number
  resultsPerPage: number
  sort: string
  filter?: string[] | null
  deviceId?: string
  fields?: string
}

type ProductsPerPage = {
  page: number
  products: Product[]
}

function serializeSortParams(searchSort: string) {
  let newSort = searchSort
  const to = [
    'price_asc',
    'price_desc',
    'orders_desc',
    'release_desc',
    'discount_desc',
    'score_desc',
  ]

  const from = [
    'ascprice',
    'descprice',
    'descsold',
    'descdate',
    'descdiscount',
    'relevance',
  ]

  for (let i = 0; i < from.length; i++) {
    newSort = newSort.replace(new RegExp(to[i]), from[i])
  }

  return newSort
}

function ProductGallery({
  title,
  department,
  sellerName,
  searchEngine,
  dataSports,
  type,
  collection,
  bannerMobile,
  bannerDesktop,
  dataBrand,
  typeValidation = 'byTermOrCollection',
  salesChannel,
}: Props) {
  const { isMobile } = useMobile()
  const { sendSearchViewEvent } = useLinxSearchView()
  const { getLinxUser } = useLinxUser()
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null)
  const [productsByPage, setProductsByPage] = useState<ProductsPerPage[]>([])
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [isFilterDesktopOpen] = useState<boolean>(true)
  const [collectionTitle, setCollectionTitle] = useState('')
  const [viewImpressionHasBeenSent, setViewImpressionHasBeenSent] =
    useState(false)

  const { href } = useLocation()

  const isSearchPage = href?.includes('pesquisa')
  const isCollectionPage = href?.includes('collection')

  const {
    state: { term, sort, selectedFacets, page },
    itemsPerPage,
    setFacet,
    setFacets,
  } = useSearch()

  const facetsv2 = searchResult?.facets
  const totalCount = searchResult?.filteredRecords ?? 0

  const resultError = useMemo(() => {
    return {
      facets: [],
      filteredRecords: 0,
      pagination: { next: 0, last: 0 },
      products: [],
      sort: [],
    }
  }, [])

  const validationsType = {
    byTermOrCollection: !term && !collection,
    byDepartment: !department,
    bySellerName: !sellerName,
    byFilter: selectedFacets.length === 0,
  }

  const validHasExistAnyTermForSearch = validationsType[typeValidation]

  const doSearch = useCallback(
    (pageNumber: number, selectedFacet: Facet[], searchSort: string) => {
      if (validHasExistAnyTermForSearch) {
        return Promise.reject('termo para busca não informado')
      }

      const linxUser = getLinxUser()
      const url = {
        collection:
          'https://decathlonstore.myvtex.com/_v3/api/decathlon-search/product/collection',
        search:
          'https://decathlonstore.myvtex.com/_v3/api/decathlon-search/product/search',
        category:
          'https://decathlonstore.myvtex.com/_v3/api/decathlon-search/product/category',
        seller: `https://decathlonstore.myvtex.com/_v3/api/decathlon-search/product/search`,
      }

      const params: ParamsApi = {
        sc: salesChannel ?? B2C.salesChannel,
        engine: searchEngine,
        term: collection ?? term,
        page: pageNumber === 0 ? 1 : pageNumber,
        resultsPerPage: itemsPerPage,
        sort: serializeSortParams(searchSort),
        ...(department && { category: department.item.replace('/', '') }),
        filter: selectedFacet.map((facet) => {
          if (facet.key === '2') {
            return `c:${searchEngine === 'linx' ? '2:3' : 'price:range'}:${
              facet.value
            }`
          }

          return `d:${facet.key}:${facet.value}`
        }),
      }

      if (searchEngine === 'linx') {
        params.deviceId = linxUser.deviceId
      }

      if (type === 'seller') {
        delete params.term
        params.filter?.push(`d:sellername:${sellerName}`)
      }

      return axios.get(`${url[type]}?${qs.stringify(params)}`)
    },
    [
      validHasExistAnyTermForSearch,
      getLinxUser,
      type,
      sellerName,
      salesChannel,
      searchEngine,
      collection,
      term,
      itemsPerPage,
      department,
    ]
  )

  useEffect(() => {
    setLoading(true)
    if (validHasExistAnyTermForSearch) {
      return
    }

    async function fetchData() {
      try {
        const { data } = await doSearch(page, selectedFacets, sort)

        setCollectionTitle(data?.collection?.name)

        const products = {
          page,
          products: data.products,
          last: data.pagination.last,
        }

        setSearchResult(data)
        setProductsByPage([products])
      } catch (error) {
        setSearchResult(resultError)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [
    doSearch,
    selectedFacets,
    sort,
    resultError,
    page,
    validHasExistAnyTermForSearch,
  ])

  useEffect(() => {
    if (!searchResult) {
      return
    }

    if (searchEngine === 'linx' && term) {
      sendSearchViewEvent({
        query: term,
        searchId: searchResult.searchId ?? '',
        products: searchResult.products,
      })
    }

    if (viewImpressionHasBeenSent) {
      return
    }

    if (isSearchPage || isCollectionPage) {
      sendSearchQueryEvent({
        name: 'intelligent_search_query',
        params: {
          url: window.location.href,
          results: searchResult.filteredRecords,
        },
      })
    }

    setViewImpressionHasBeenSent(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEngine, searchResult, sendSearchViewEvent])

  // Statsig events
  useEffect(() => {
    if (!isSearchPage || loading) {
      return
    }

    if (!searchResult?.products.length) {
      Statsig.logEvent('chegou_na_PLP_sem_resultado', null, {
        device: isMobile ? 'mobile' : 'desktop',
      })

      return
    }

    Statsig.logEvent('chegou_na_PLP_com_resultado', null, {
      device: isMobile ? 'mobile' : 'desktop',
    })
  }, [isMobile, isSearchPage, loading, searchResult])

  const facetBrandDecathlon = facetsv2?.find(
    (facet) => facet.key === '77560' || facet.key === 'marca-propria'
  )

  const facetBrandDecathlonValue = facetBrandDecathlon?.values.find(
    (value) => value.value === '77654' || value.value === 'marcas-decathlon'
  )

  if (searchResult?.products.length === 0) {
    return (
      <Section className="w-full mx-auto">
        {selectedFacets.length > 0 ? (
          <FilteredEmptyGallery />
        ) : (
          <SearchNotFound term={title} />
        )}
      </Section>
    )
  }

  const GalleryPageSkeleton = <ProductGridSkeleton loading />
  const GalleryFilterSkeleton = <ProductFilterSkeleton loading />

  return (
    <Section
      data-testid="product-gallery"
      className={
        dataSports
          ? 'product-listing layout__content-full mt-14 md:mt-20'
          : 'product-listing layout__content-full'
      }
    >
      {bannerDesktop && bannerMobile && (
        <>
          <img
            src={bannerDesktop}
            alt="Copa do mundo | Decathlon"
            className="desk"
          />

          <img
            src={bannerMobile}
            alt="Copa do mundo | Decathlon"
            className="mob"
          />
        </>
      )}

      <div className="gap-6 product-listing__content-grid layout__content">
        {isFilterDesktopOpen && (
          <div className="product-listing__filters-bar">
            {loading ? (
              GalleryFilterSkeleton
            ) : (
              <>
                <div className="w-full">
                  <div className="flex items-center justify-between mt-4 mb-6 display-mobile">
                    {facetBrandDecathlon && facetBrandDecathlonValue && (
                      <Switch
                        testId={`desktop-${facetBrandDecathlon.key}`}
                        facet={facetBrandDecathlon}
                        facetValue={facetBrandDecathlonValue}
                        onFacetChange={() => {
                          if (facetBrandDecathlonValue.selected) {
                            const newFacet = selectedFacets.filter(
                              (obj) => obj.key !== facetBrandDecathlon.key
                            )

                            setFacets(newFacet)
                          } else {
                            setFacet({
                              key: facetBrandDecathlon.key,
                              value: facetBrandDecathlonValue.value,
                            })
                          }
                        }}
                      >
                        <h3 className="flex items-center gap-2 mr-2 font-bold font-roboto text-deepGray">
                          Marcas
                          <Logo width={80} height={20} bgFill="#3643BA" />
                        </h3>
                      </Switch>
                    )}
                    <button
                      data-testid="open-filter-button"
                      aria-label="Open Filters"
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                      className="flex items-center gap-2 px-3 py-2 ml-auto border display-mobile border-restructure-border-secondary rounded-round"
                    >
                      <p className="mobile-body-regular-text3 text-restructure-secondary">
                        Filtrar{' '}
                        {selectedFacets?.length > 0
                          ? `(${selectedFacets.length})`
                          : null}
                      </p>
                      <IconFilterMobile />
                    </button>
                  </div>

                  <div className="flex items-center justify-between mb-2 ">
                    <h2 className="max-w-[220px] md:max-w-none flex items-center gap-2  capitalize text-xl text-[#001018] font-bold font-roboto">
                      {collectionTitle?.replace(/-/g, ' ') ??
                        title?.replace(/-/g, ' ') ??
                        term?.replace(/-/g, ' ')}
                      <span
                        data-testid="total-product-count"
                        data-count={totalCount}
                        className="mobile-caption-regular tablet-caption-regular desktop-caption-regular text-restructure-tertiary"
                      >
                        ({`${totalCount.toLocaleString()} resultados`})
                      </span>
                    </h2>
                    <div className="display-mobile">
                      <SortV2 id="mobile" />
                    </div>
                  </div>
                </div>

                <FilterV2
                  isOpen={isFilterOpen}
                  facetsv2={facetsv2}
                  onDismiss={() => setIsFilterOpen(false)}
                />
              </>
            )}
          </div>
        )}

        <div
          className={`product-listing__results-wrapper ${
            !isFilterDesktopOpen ? 'full !bg-inherit !h-auto' : ''
          }`}
        >
          <div className="hidden-mobile">
            <div className="justify-end product-listing__results-wrapper-top">
              <div className="ml-auto mr-0 product-listing__sort">
                {loading ? (
                  <Skeleton height={24} width={188} className="mr-[32px]" />
                ) : (
                  <SortV2 id="desktop" />
                )}
              </div>
            </div>
          </div>
          {loading ? (
            GalleryPageSkeleton
          ) : (
            <ProductResultList
              productsByPage={productsByPage}
              title={collectionTitle ?? title}
              totalProducts={searchResult?.filteredRecords ?? 0}
              registerPerPage={itemsPerPage}
            />
          )}
          {dataSports && <RelatedResearch dataCMS={dataSports} />}
          {dataSports && <ContentSEO dataCMS={dataSports} />}
          {dataBrand && <RelatedResearchBrand dataCMS={dataBrand} />}
          {dataBrand && <ContentSEOBrand dataCMS={dataBrand} />}
        </div>
      </div>
    </Section>
  )
}

ProductGallery.displayName = 'ProductGallery'
export default mark(ProductGallery)
