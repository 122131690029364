export function IconFilterMobile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_3903_13506)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8335 13.3333C8.8335 13.0572 9.05735 12.8333 9.3335 12.8333L14.0002 12.8333C14.2763 12.8333 14.5002 13.0572 14.5002 13.3333C14.5002 13.6095 14.2763 13.8333 14.0002 13.8333L9.3335 13.8333C9.05735 13.8333 8.8335 13.6095 8.8335 13.3333Z"
          fill="#3E4751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 13.3333C1.5 13.0572 1.72386 12.8333 2 12.8333L6.66667 12.8333C6.94281 12.8333 7.16667 13.0572 7.16667 13.3333C7.16667 13.6095 6.94281 13.8333 6.66667 13.8333L2 13.8333C1.72386 13.8333 1.5 13.6095 1.5 13.3333Z"
          fill="#3E4751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 8C7.5 7.72386 7.72386 7.5 8 7.5L14 7.5C14.2761 7.5 14.5 7.72386 14.5 8C14.5 8.27614 14.2761 8.5 14 8.5L8 8.5C7.72386 8.5 7.5 8.27614 7.5 8Z"
          fill="#3E4751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 8C1.5 7.72386 1.72386 7.5 2 7.5L5.33333 7.5C5.60948 7.5 5.83333 7.72386 5.83333 8C5.83333 8.27614 5.60948 8.5 5.33333 8.5L2 8.5C1.72386 8.5 1.5 8.27614 1.5 8Z"
          fill="#3E4751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1665 2.66667C10.1665 2.39053 10.3904 2.16667 10.6665 2.16667L13.9998 2.16667C14.276 2.16667 14.4998 2.39053 14.4998 2.66667C14.4998 2.94281 14.276 3.16667 13.9998 3.16667L10.6665 3.16667C10.3904 3.16667 10.1665 2.94281 10.1665 2.66667Z"
          fill="#3E4751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 2.66667C1.5 2.39053 1.72386 2.16667 2 2.16667L8 2.16667C8.27614 2.16667 8.5 2.39053 8.5 2.66667C8.5 2.94281 8.27614 3.16667 8 3.16667L2 3.16667C1.72386 3.16667 1.5 2.94281 1.5 2.66667Z"
          fill="#3E4751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.3335 15.8333C9.05735 15.8333 8.8335 15.6095 8.8335 15.3333L8.8335 11.3333C8.8335 11.0572 9.05735 10.8333 9.3335 10.8333C9.60964 10.8333 9.8335 11.0572 9.8335 11.3333L9.8335 15.3333C9.8335 15.6095 9.60964 15.8333 9.3335 15.8333Z"
          fill="#3E4751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.3335 10.5C5.05735 10.5 4.8335 10.2761 4.8335 10L4.8335 6C4.8335 5.72386 5.05735 5.5 5.3335 5.5C5.60964 5.5 5.8335 5.72386 5.8335 6L5.8335 10C5.8335 10.2761 5.60964 10.5 5.3335 10.5Z"
          fill="#3E4751"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6665 5.16667C10.3904 5.16667 10.1665 4.94281 10.1665 4.66667L10.1665 0.666672C10.1665 0.390529 10.3904 0.166672 10.6665 0.166672C10.9426 0.166672 11.1665 0.390529 11.1665 0.666672L11.1665 4.66667C11.1665 4.94281 10.9426 5.16667 10.6665 5.16667Z"
          fill="#3E4751"
        />
      </g>
      <defs>
        <clipPath id="clip0_3903_13506">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 16) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
