import { Link } from 'gatsby'
import { useState, useEffect } from 'react'

type Props = {
  dataCMS: any
}

export function RelatedResearch({ dataCMS }: Props) {
  const [relatedData, setRelatedData] = useState<any>()

  useEffect(() => {
    const data = dataCMS.sections.filter(
      (item: any) =>
        item.name === '5.5 Nuvem de termos relacionados' &&
        (item.data.configuration === 'exibir website e aplicativo' ||
          item.data.configuration === 'exibir apenas website')
    )

    if (data.length) {
      setRelatedData(data[0].data)
    } else {
      setRelatedData(null)
    }
  }, [relatedData, dataCMS.sections])

  return (
    <>
      {relatedData && (
        <div style={{ marginTop: '106px' }}>
          <section className="section-title-seo">
            {relatedData.moduleTitle && (
              <span className="text-darkGray text-sm">
                {relatedData.moduleTitle}
              </span>
            )}
            {relatedData.moduleDescription && (
              <p className="text-xl text-neutral10">
                {relatedData.moduleDescription}
              </p>
            )}
          </section>
          {relatedData.cardsTerms && (
            <div className="section-terms-seo mt-5">
              <ul className="flex flex-wrap gap-2.5">
                {/* eslint-disable-next-line */}
                {relatedData.cardsTerms.map((item: any, index: number) => (
                  <li key={index}>
                    <Link
                      to={item.URLWeb}
                      className="text-sm text-darkGray border border-solid rounded p-2"
                      rel="nofollow"
                      style={{ borderColor: '#D9DDE1' }}
                    >
                      {item.termName}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  )
}
