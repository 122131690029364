import Icon from 'src/components/ui/Icon'
import { useDeferredValue, useMemo, useState } from 'react'
import type { IStoreSelectedFacet } from '@faststore/api'

import type { FacetValue } from '../types'

type Props = {
  brands: FacetValue[]
  onFacetChange: (value: IStoreSelectedFacet) => void
  onClose: () => void
}

export function AllBrandsModal({ brands, onFacetChange, onClose }: Props) {
  const [termSearch, setTermSearch] = useState('')

  const deferredTermSearch = useDeferredValue(termSearch)

  const brandsPerLetter = useMemo(() => {
    let brandsActive = brands

    if (deferredTermSearch) {
      brandsActive = brands.filter((brand) =>
        brand.label.toLowerCase().includes(deferredTermSearch)
      )
    }

    return brandsActive.reduce((acc, brand) => {
      const firstLetterName = brand.label.slice(0, 1).toLowerCase() ?? ''

      if (!acc) {
        acc = {}
      }

      if (!acc[firstLetterName]) {
        acc[firstLetterName] = []
      }

      acc[firstLetterName].push(brand)

      return acc
    }, {} as { [key: string]: FacetValue[] })
  }, [deferredTermSearch, brands])

  return (
    <div>
      <div className="flex items-center justify-between pr-6">
        <p className="p-6 pb-0 font-bold text-deepGray font-roboto">
          Todas as Marcas
        </p>
        <button onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z"
              fill="#00689D"
            />
          </svg>
        </button>
      </div>

      <div className="mx-6 mt-6 border border-[#8996A2] h-[48px] rounded flex items-center px-[12px]">
        <input
          type="text"
          value={termSearch}
          onChange={(e) => setTermSearch(e.target.value)}
          placeholder="Buscar marca"
          className="flex-1 outline-none"
        />
        <span>
          <Icon
            name="MagnifyingGlass"
            data-testid="store-search-button"
            className="w-[20px] h-[20px]"
          />
        </span>
      </div>
      <div className="pl-6 pr-2 pb-6 ">
        <div className="max-h-[336px] pr-6 h-full overflow-auto">
          {Object.entries(brandsPerLetter).map((item) => {
            return (
              <ul
                className="py-[24px] border-b border-mediumGray font-roboto"
                key={item[0]}
              >
                <li className="flex">
                  <p className="text-lg font-bold text-deepGray mr-6 uppercase font-roboto">
                    {item[0]}
                  </p>
                  <ul className="flex flex-wrap gap-4 w-full">
                    {item[1].map((brand) => (
                      <li
                        className={`${
                          brand.selected ? 'text-blue' : 'text-deepGray'
                        } w-[calc(100%/3-25px)]`}
                        key={brand.value}
                        data-value={brand.label
                          .replaceAll(' ', '-')
                          .toLowerCase()}
                        data-quantity={brand.quantity}
                        data-aria-label-select={brand.selected}
                      >
                        <button
                          className="w-full h-full text-left font-roboto"
                          onClick={() => {
                            onFacetChange({
                              key: brand.key,
                              value: brand.value,
                            })

                            onClose()
                          }}
                        >
                          {brand.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            )
          })}
        </div>
      </div>
    </div>
  )
}
