import { useCallback } from 'react'

import type { LinxMetaSearch } from '../types'
import { useLinxEventDispatch } from './useLinxEventDispatch'

type Products = Array<{
  productId: string
  items: Array<{
    itemId: string
  }>
}>

type SendSearchProps = {
  query: string
  searchId: string
  products: Products
}

export const useLinxSearchView = () => {
  const { dispatchLinxEvent } = useLinxEventDispatch()

  const mapperLinxItems = (products: Products): LinxMetaSearch['items'] => {
    return products.map((product) => ({
      pid: product.productId,
    }))
  }

  const sendSearchViewEvent = useCallback(
    ({ query, products, searchId }: SendSearchProps) => {
      let linxMetaSearch: LinxMetaSearch = {
        page: products?.length ? 'search' : 'emptysearch',
        query,
        searchId,
      }

      if (products?.length) {
        linxMetaSearch = {
          ...linxMetaSearch,
          items: mapperLinxItems(products),
        }
      }

      return dispatchLinxEvent(linxMetaSearch)
    },
    [dispatchLinxEvent]
  )

  return { sendSearchViewEvent }
}
